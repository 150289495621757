import React, { Component } from 'react'
import emailjs from '@emailjs/browser';
import '../styles/Form.css';

class Form extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    year: '',
    make: '',
    model: '',
    desc: '',
    sending: 'no',
    blankInputs: [],
  }

sendEmail(e) {
    e.preventDefault();

    const { name, email, phone, year, make, model, desc } = this.state;
    let blankInputs = [];

    this.setState({
        blankInputs: []
    }, () => {
        if (name.length === 0) {
            blankInputs.push('name');
        }
        if (email.length === 0) {
            blankInputs.push('email');
        }
        if (phone.length === 0) {
            blankInputs.push('phone');
        }
        if (year.length === 0) {
            blankInputs.push('year');
        }
        if (make.length === 0) {
            blankInputs.push('make');
        }
        if (model.length === 0) {
            blankInputs.push('model');
        }
        if (desc.length === 0) {
            blankInputs.push('desc');
        }

        console.log(e);
    
        if (blankInputs.length === 0) {
            this.setState({
                sending: 'sending'
            }, () => {
                emailjs.sendForm('service_9ogxuwr', 'template_tziamu8', e.target, 'T2k5m4O9Fn64ApoKD')
                  .then((result) => {
                      console.log(result.text);
                      this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        year: '',
                        make: '',
                        model: '',
                        desc: '',
                        sending: 'sent'
                      })
                  }, (error) => {
                      console.log(error.text);
                  });
            })
        } else {
            this.setState({
                blankInputs: blankInputs,
            })
        }
    })
  };

  handleChange(e) {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  getSubmit() {
    const sending = this.state.sending;

    switch(sending) {
        case 'no':
            return <input type='submit' value="Submit" className='submit-btn' />
        case 'sending':
            return <input disabled type='submit' value="Sending..." className='submit-btn sending' />
        case 'sent':
            return <input disabled type='submit' value="Sent!" className='submit-btn sent' />
        default:
            return <input type='submit' value="Submit" className='submit-btn' />
    }
  }

  render() {
    return (
        <div>
            <p style={{margin: "5px auto", color: "red", fontSize: "14px", position: "relative", opacity: this.state.blankInputs.length === 0 ? "0" : "1"}}><i>Please fill out all fields.</i></p>
            <form onSubmit={e => this.sendEmail(e)}>
                <input 
                    disabled={this.state.sending === 'sent'}
                    className={this.state.blankInputs.includes('name') ? 'form-input error' : 'form-input'}
                    placeholder='Name' 
                    type='text' name='name' 
                    onChange={e => this.handleChange(e)}
                    value={this.state.name}
                />
                <input 
                    disabled={this.state.sending === 'sent'}
                    className={this.state.blankInputs.includes('email') ? 'form-input error' : 'form-input'}
                    placeholder='Email' 
                    type='text' name='email' 
                    onChange={e => this.handleChange(e)}
                    value={this.state.email}
                />
                <input 
                    disabled={this.state.sending === 'sent'}
                    className={this.state.blankInputs.includes('phone') ? 'form-input error' : 'form-input'}
                    placeholder='Phone' 
                    type='text' name='phone' 
                    onChange={e => this.handleChange(e)}
                    value={this.state.phone}
                />
                <input 
                    disabled={this.state.sending === 'sent'}
                    className={this.state.blankInputs.includes('year') ? 'form-input error year' : 'form-input year'}
                    placeholder='Year' 
                    type='text' name='year' 
                    onChange={e => this.handleChange(e)}
                    value={this.state.year}
                />
                <input 
                    disabled={this.state.sending === 'sent'}
                    className={this.state.blankInputs.includes('make') ? 'form-input error make' : 'form-input make'}
                    placeholder='Make' 
                    type='text' name='make' 
                    onChange={e => this.handleChange(e)}
                    value={this.state.make}
                />
                <input 
                    disabled={this.state.sending === 'sent'}
                    className={this.state.blankInputs.includes('model') ? 'form-input error model' : 'form-input model'}
                    placeholder='Model' 
                    type='text' name='model' 
                    onChange={e => this.handleChange(e)}
                    value={this.state.model}
                />
                <textarea 
                    disabled={this.state.sending === 'sent'}
                    className={this.state.blankInputs.includes('desc') ? 'form-input error' : 'form-input'}
                    placeholder='Service Description' 
                    name='desc' 
                    rows="7"
                    onChange={e => this.handleChange(e)}
                    value={this.state.desc}
                />
                {this.getSubmit()}
            </form>
        </div>
    )
  }
}

export default Form;
