import React, { Component } from 'react';
import '../styles/Home.css';
import Navbar from '../components/Navbar';
import Hero from './Hero';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Form from './Form';

class Home extends Component {
  scrollToAppt(e) {
    e.preventDefault();
    document.getElementById("appt-container").scrollIntoView({behavior: 'smooth'});
  }

  componentDidMount() {
    document.title = this.props.title
  }

  render() {
    return (
        <div className="home-container">
            <Navbar active="home"/>
            <Hero image="bmw-rear" content={
                <div className="hero-text">
                    <h1 style={{display: "inline-block"}}>Dream Garage </h1>
                    <h1 className="home-hero-title" style={{display: "inline-block"}}>HQ</h1>
                    <h4 className="home-hero-text">BY ENTHUSIASTS FOR ENTHUSIASTS</h4>
                    <button className='hero-btn' onClick={e => this.scrollToAppt(e)}>Make an Appointment Now!</button>
                </div>
            }/>
            <div className='page-container'>
                <h2>We Are Dream Garage HQ</h2>
                <p>&emsp;Here at Dream Garage HQ, we share a passion for cars, speed and performance. We understand the importance of proper maintenance, and the satisfaction of going fast and having a smooth ride in the same beautiful car.</p>
                <p>&emsp;Bring your car down to our shop and we'll show you our expertise and drive. We guarantee you'll feel the Dream Garage HQ difference the moment you get back behind the wheel.</p>
                <Link to="/about-us">
                    <button className='page-btn'>Learn More About Us!</button>
                </Link>
            </div>
            <div className='car-img-container'>
                <div className='car-img-1'></div>
            </div>
            <div className='address-container'>
                <div className='loc-hrs-container'>
                    <h2>Location and Hours</h2>
                    <p>&emsp;Our shop and central place of operation is located on Long Island, New York in West Babylon.</p>
                    <p>&emsp;However, we also ship parts across the country. Let us be your first choice for any car-part needs.</p>
                    <p style={{marginBottom: "0px"}}>&emsp;Or you can reach out to us directly about making an appointment, bringing your car down and letting us take care of whatever you need.</p>
                    <div className='add-hours-container'>
                        <div className='add-hours-left'>
                            <p>Address:</p>
                            <p>Hours of Operation:</p>
                        </div>
                        <div className='add-hours-right'>
                            <p>75 Dale St, West Babylon, NY 11704</p>
                            <p style={{margin: "5px auto"}}>Mon thru Friday: 9am - 6pm</p>
                            <p style={{margin: "5px auto"}}>Saturday: 9am - 6pm</p>
                            <p style={{margin: "5px auto"}}>Closed Sunday</p>
                        </div>
                    </div>
                </div>
                <div className='map-img'>
                    {/* <img src={Map} alt="75 Dale St, West Babylon, NY 11704 on Google Maps" /> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.3901945583398!2d-73.3919390195127!3d40.73143888759398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e82b809696186d%3A0x577e0548dd9ded21!2sDream%20Garage%20HQ!5e0!3m2!1sen!2sus!4v1666240103387!5m2!1sen!2sus" title="Dream Garage HQ on Google Maps"style={{border: "none", width: "100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div className='desktop-only'>
                <div className='car-img-container'>
                    <div className='car-img-1' style={{marginTop: "0px"}}></div>
                </div>
            </div>
            <div className='services-container'>
                <h2>Services</h2>
                <p>&emsp;Our goal is to be your one-stop shop for anything and everything you might need for your vehicle. We can perform many professional-quality services, including...</p>
                <div className='service-img service-1'>
                    <h3>Engine Maintenance</h3>
                </div>
                <div className='service-img service-2'>
                    <h3>Custom Builds</h3>
                </div>
                <div className='service-img service-3'>
                    <h3>Aftermarket Upgrades</h3>
                </div>
                <div className='service-img service-4'>
                    <h3>Detailing</h3>
                </div>
                <p style={{textAlign: "center", marginTop: "50px", marginBottom: "150px"}}>...and so much more!</p>
            </div>
            <div className='appt-container' id='appt-container'>
                <div className='appt-text'>
                    <h2>Need an Appointment?</h2>
                    <p>&emsp;If you'd like to make an appointment, please fill out this form with your car's make and model, and your name, email address and/or phone number, so that we can get back to you.</p>
                    <p style={{textAlign: "center", margin: "40px auto"}}>We hope to hear from you soon!</p>
                </div>
                <div className='form-container'>
                    <Form />
                </div>
            </div>
            <Footer />
        </div>
    )
  }
}

export default Home