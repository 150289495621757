import React, { Component } from 'react';
import '../styles/Hero.css';

class Hero extends Component {
  getHero() {
    const image = this.props.image;
    const content = this.props.content;
    switch(image) {
        case "bmw-rear":
            return <div className='hero-image bmw-rear'>{content}</div>;
        case "car-repair":
            return <div className='hero-image car-repair'>{content}</div>;
        case "cars-hero":
            return <div className='hero-image cars-hero'>{content}</div>;
        default:
            return <div className='hero-image car1'>{content}</div>;
    }
  }

  render() {
    return (
      <div className="hero-container">
        {this.getHero()}
      </div>
    )
  }
}

export default Hero