import React, { Component } from 'react';
import '../styles/AboutUs.css';
import Footer from './Footer';
import Form from './Form';
import Hero from './Hero';
import Navbar from './Navbar';

class AboutUs extends Component {
  scrollToAppt(e) {
    e.preventDefault();
    document.getElementById("appt-container").scrollIntoView({behavior: 'smooth'});
  }

  componentDidMount() {
    document.title = this.props.title
  }

  render() {
    return (
        <div className="about-us-container">
          <Navbar active="about"/>
          <Hero image="car-repair" content={
              <div className="hero-text">
                  <h1>About Us</h1>
                  <h4>PASSION FOR PERFORMANCE</h4>
                  <button className='hero-btn' onClick={e => this.scrollToAppt(e)}>Make an Appointment Now!</button>
              </div>
          }/>
          {/* <div className='ab-image team-1'></div> */}
          <div className='ab-text'>
            <h2>Mission Statement</h2>
            <p>&emsp;At the core of every great company is a solid team of knowledgeable, hard-working, and passionate people who share a common interest in providing the best services or products they can.</p>
            <p>&emsp;Dream Garage HQ is no exception to this rule. We are dedicated to providing only the best for your vehicle. Whether you need a simple brake job or a complete engine overhaul, you will notice the Dream Garage HQ difference.</p>
            <p>&emsp;We know how important your car is to you. Our daily-drivers need to be smooth and reliable, and look as nice as possible in the process. Our fun project cars need to be treated properly and only upgraded with the finest parts on the market. We treat our clients' cars even better than we treat our own.</p>
            <p>&emsp;Make an appointment to stop by the shop and see for yourself how Dream Garage HQ can step your vehicle's performance up to the next level.</p>
            <p style={{textAlign: "right", fontSize: "14px", marginTop: "40px"}}>- Sal, Pete, and everyone else at Dream Garage HQ</p>
          </div>
          {/* <div className='ab-image team-2'></div> */}
          {/* <h2>Reviews</h2> */}
          <div className='appt-container' id='appt-container'>
            <div className='appt-text'>
                <h2>Need an Appointment?</h2>
                <p>&emsp;If you'd like to make an appointment, please fill out this form with your car's make and model, and your name, email address and/or phone number, so that we can get back to you.</p>
                <p style={{textAlign: "center", margin: "40px auto"}}>We hope to hear from you soon!</p>
            </div>
            <div className='form-container'>
                <Form />
            </div>
          </div>
          <Footer />
        </div>
    )
  }
}

export default AboutUs