import React, { Component } from 'react';
import '../styles/Footer.css';

class Footer extends Component {
  render() {
    return (
      <div className='footer-container'>
        <h6>Copyright © 2022 CK DevDesign. All Rights Reserved</h6>
      </div>
    )
  }
}

export default Footer