import React, { Component } from 'react';
import DGLogo from '../images/dg-logo.png';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

class Navbar extends Component {
  state = {
    isOpen: false,
    active: 'home',
  }

  toggleMenu(e) {
    e.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  setActive(e, page) {
    this.setState({
      isOpen: false,
      active: page,
    })
  }

  componentDidMount() {
    this.setState({
      active: this.props.active
    }, () => {
      window.scrollTo(0, 0);
    })
  }

  render() {
    return (
      <div className='navbar-container' id='navbar'>
        <div className='mobile-only'>
          <div className='navbar-logo'>
            <img src={DGLogo} alt="Dream Garage HQ Logo" />
          </div>
          <div className='menu-btn-container'>
            <div className='menu-btn' onClick={e => this.toggleMenu(e)}>
              <div className='menu-rect' style={{marginTop: "16px"}}></div>
              <div className='menu-rect'></div>
              <div className='menu-rect'></div>
            </div>
          </div>
          <div className='menu-popup-container'>
            <div className={this.state.isOpen ? 'background-overlay' : 'background-overlay hidden'} onClick={e => this.toggleMenu(e)}></div>
            <div className={this.state.isOpen ? 'menu-container' : 'menu-container menu-hide'}>
              <div className={this.state.isOpen ? 'menu-exit-btn' : 'menu-exit-btn menu-hide'} onClick={e => this.toggleMenu(e)}>
                <div className='menu-exit-rect-1'></div>
                <div className='menu-exit-rect-2'></div>
              </div>
              <div className='menu-item-container'>
                <Link to="/">
                  <div className={this.state.active !== 'home' ? 'menu-item' : 'menu-item menu-item-active'} onClick={e => this.setActive(e, 'home')}>
                    <h3>Home</h3>
                  </div>
                </Link>
                <Link to="/about-us">
                  <div className={this.state.active !== 'about' ? 'menu-item' : 'menu-item menu-item-active'} onClick={e => this.setActive(e, 'about')}>
                    <h3>About Us</h3>
                  </div>
                </Link>
                <Link to="/socials">
                  <div className={this.state.active !== 'socials' ? 'menu-item' : 'menu-item menu-item-active'} onClick={e => this.setActive(e, 'socials')}>
                    <h3>Socials</h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='desktop-only'>
          <div className='nav-width'>
            <div className='nav-img-container'>
              <div className='navbar-logo'>
                <img src={DGLogo} alt="Dream Garage HQ Logo" />
              </div>
            </div>
            <div className='nav-item-container'>
                <Link to="/">
                  <div className={this.state.active !== 'home' ? 'menu-item' : 'menu-item menu-item-active'} onClick={e => this.setActive(e, 'home')}>
                    <h3>Home</h3>
                  </div>
                </Link>
                <Link to="/about-us">
                  <div className={this.state.active !== 'about' ? 'menu-item' : 'menu-item menu-item-active'} onClick={e => this.setActive(e, 'about')}>
                    <h3>About Us</h3>
                  </div>
                </Link>
                <Link to="/socials">
                  <div className={this.state.active !== 'socials' ? 'menu-item' : 'menu-item menu-item-active'} onClick={e => this.setActive(e, 'socials')}>
                    <h3>Socials</h3>
                  </div>
                </Link>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Navbar