import React, { Component } from 'react';
import '../styles/Socials.css';
import Hero from './Hero';
import IG from '../images/ig.png';
import YT from '../images/yt.png';
import TT from '../images/tt.png';
import FB from '../images/fb.png';
import Footer from './Footer';
import Navbar from './Navbar';
import Form from './Form';

class Socials extends Component {
  scrollToAppt(e) {
    e.preventDefault();
    document.getElementById("appt-container").scrollIntoView({behavior: 'smooth'});
  }

  componentDidMount() {
    document.title = this.props.title;
  }

  render() {
    return (
        <div className="socials-container">
          <Navbar active="socials"/>
          <Hero image="cars-hero" content={
              <div className="hero-text">
                  <h1>Socials</h1>
                  <h4>JOIN OUR COMMUNITY</h4>
                  <button className='hero-btn' onClick={e => this.scrollToAppt(e)}>Make an Appointment Now!</button>
              </div>
          }/>
          <div className='links-container'>
            <h2>Follow Us on All Platforms!</h2>
            <div className='social-btn-container'>
              <a href="https://www.instagram.com/dreamgaragehq/" target="_blank" rel="noopener noreferrer">  
                <button className='social-btn ig'>
                  <h4>@dreamgaragehq on Instagram</h4>
                  <img src={IG} alt='Instagram Logo' />
                </button>
              </a>
              <a href="https://www.youtube.com/channel/UCOhlsGbpGwD42ZVx_iYLWpQ" target="_blank" rel="noopener noreferrer">  
                <button className='social-btn yt'>
                  <h4>@dreamgaragehq on YouTube</h4>
                  <img src={YT} alt='YouTube Logo' />
                </button>
              </a>
              <a href="https://www.tiktok.com/@dreamgaragehq" target="_blank" rel="noopener noreferrer">  
                <button className='social-btn tt'>
                  <h4>@dreamgaragehq on TikTok</h4>
                  <img src={TT} alt='TikTok Logo' />
                </button>
              </a>
              <a href="https://www.facebook.com/DreamGarageHQ" target="_blank" rel="noopener noreferrer">  
                <button className='social-btn fb'>
                  <h4>@dreamgaragehq on Facebook</h4>
                  <img src={FB} alt='Facebook Logo' />
                </button>
              </a>
            </div>
          </div>
          <div className='appt-container' id='appt-container'>
            <div className='appt-text'>
                <h2>Need an Appointment?</h2>
                <p>&emsp;If you'd like to make an appointment, please fill out this form with your car's make and model, and your name, email address and/or phone number, so that we can get back to you.</p>
                <p style={{textAlign: "center", margin: "40px auto"}}>We hope to hear from you soon!</p>
            </div>
            <div className='form-container'>
                <Form />
            </div>
          </div>
          <Footer />
        </div>
    )
  }
}

export default Socials