import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Socials from './components/Socials';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/"  element={<Home title="Home - DreamGarageHQ"/>} />
          <Route path="/about-us"  element={<AboutUs title="About Us - DreamGarageHQ"/>} />
          <Route path="/socials"   element={<Socials title="Socials - DreamGarageHQ"/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
